@tailwind base;
@tailwind components;
@tailwind utilities;

html,
button,
input,
optgroup,
select,
textarea {
  line-height: 1.15;
}

body {
  font-size: 15px;
}


.form-label {
  @apply block mb-2 text-sm font-normal;
  color: #797979;
  font-weight: 500;
}

.form-input {
  @apply w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none;
}

.secondary-btn {
  @apply text-black bg-transparent;
}

.secondary-btn:hover {
  @apply bg-transparent;
}

.disabled-btn {
  @apply inline-flex items-center p-4 text-white bg-gray-400 rounded shadow;
}

.form-error-message {
  @apply text-xs italic text-red-500;
}

.bg-nav {
  @apply bg-gray-800;
}

.main-nav {
  @apply flex flex-wrap items-center justify-between p-4;
}

.main-logo {
  @apply flex items-center text-white;
}

.menu-item {
  @apply block p-4 no-underline;
}

/* Modal */
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* Table */
@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

/* td:not(:last-child) {
  border-bottom: 0;
}
th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
} */

/* Override Toastify__toast */
.Toastify__toast-container {
  /*width: 600px !important;*/
}

/* User avatar */
.line-height-username1 {
  line-height: 2.4;
}

.line-height-username2 {
  line-height: 5.5rem;
}

.line-height-username3 {
  line-height: 7.5rem;
}

/* Highlight */
.border-hi {
  box-shadow: 0px 1px 8px 1px rgba(179, 15, 116, 0.5) !important;
}

/* Batch Pulse */
.batch-pulse {
  animation: shadow-batch-pulse;
  animation-duration: 5.5s !important;
  animation-iteration-count: infinite !important;
  animation-fill-mode: forwards !important;
}

@keyframes shadow-batch-pulse {
  0% {
    border: 3px solid #ce1068 !important;
    box-shadow: 0px 10px 28px 20px rgba(179, 15, 116, 0.4);
  }

  50% {
    border: 6px solid #7a1372 !important;
    box-shadow: 0px 5px 12px 10px rgba(221, 14, 236, 0.1);
  }

  100% {
    border: 3px solid #ce1068 !important;
    box-shadow: 0px 10px 28px 20px rgba(179, 15, 116, 0.4);
  }
}

.focus\:ring:focus {
  box-shadow: 0 0 0 2px rgba(214, 62, 151, 0.5);
}

button:disabled {
  color: #999;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.mainHeader {
  max-width: 1300px;
  margin: 0 auto;
}

.filePreview .pdf-canvas canvas {
  width: 100% !important;
}

.filePreview .pg-viewer-wrapper {
  overflow: hidden !important;
}

.filePreview .photo-viewer-container {
  height: 100% !important;
}

.pageWrapper {
  border: 1px solid #e2e8f0 !important;
  box-shadow: 1px 1px 5px rgba(47, 133, 90, 0.2) !important;
}

.pageHeader {
  height: 80px;
  align-items: center;
}

.selectWrapper {
  button {
    border-color: #ddd;

    &>div {
      height: 35px;
    }

    input {
      font-weight: normal;
    }
  }
}

/* Breadcrumb from kutty css */
.breadcrumb,
.prose ul.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  white-space: nowrap;
}

.breadcrumb-item {
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgba(100, 116, 139, var(--tw-text-opacity));
}

.breadcrumb-item a,
.prose .breadcrumb-item a {
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgba(15, 23, 42, var(--tw-text-opacity));
  text-decoration: none;
}

.breadcrumb-item,
.breadcrumb-item a,
.prose ul>li.breadcrumb-item {
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
}

.prose li.breadcrumb-item:first-child {
  padding-left: 0;
}

.breadcrumb-sm .breadcrumb-item,
.breadcrumb-sm .breadcrumb-item a {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.breadcrumb-lg .breadcrumb-item,
.breadcrumb-lg .breadcrumb-item a {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.breadcrumb-item svg {
  flex: none;
  height: 1rem;
  margin-right: 0.25rem;
  width: 1rem;
}

.breadcrumb-sm .breadcrumb-item svg {
  height: 0.75rem !important;
  width: 0.75rem !important;
}

.breadcrumb-lg .breadcrumb-item svg {
  height: 1.25rem !important;
  width: 1.25rem !important;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.75rem;
}

.prose ul>li.breadcrumb-item:before {
  background-color: initial;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}

.breadcrumb-item+.breadcrumb-item:before {
  display: inline-block;
  padding-right: 0.75rem;
  --tw-text-opacity: 1;
  color: rgba(100, 116, 139, var(--tw-text-opacity));
  content: '/';
}

.breadcrumb-arrow .breadcrumb-item+.breadcrumb-item:before {
  content: '→' !important;
}

.breadcrumb-arrowhead .breadcrumb-item+.breadcrumb-item:before {
  content: '>' !important;
}

.breadcrumb-bullet .breadcrumb-item+.breadcrumb-item:before {
  content: '·' !important;
}

.breadcrumb.breadcrumb-dark .breadcrumb-item a,
.prose ul.breadcrumb.breadcrumb-dark .breadcrumb-item a {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@import './custom.css';