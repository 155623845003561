/* @import url('./fonts/fonts.css'); */

:root {
  --navbar-height: 100px;

  /* color */
  --white-color: #ffffff;
  --black-color: #000000;

  --primary-color: #ffffff;
  --secondary-color: #2e3192;
  --third-color: #5b5d6b;
  --fourth-color: #ed9c03;
  --fifth-color: #33a451;
  --sixth-color: #ea3f30;

  --bg-primary-color: #ffffff;
  --bg-secondary-color: #2e3192;
  --bg-danger-color: #c82333;
  --bg-filled-color: #f2f3f7;
  --bg-disabled-color: #2e3192;

  --primary-border-color: #5b5d6b;
  --secondary-border-color: #d9d9d9;
}

.content-container {}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content__top {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  flex: 0 0 auto;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.content__top--left button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 0;
}

.content__top--right {
  display: flex;
}

.content__top--right span {
  width: 100px;
}

.content__center {
  flex: 1 0 auto;
}

.content__center>h1 {
  font-weight: 700;
  font-size: 25px;
  margin: 0 0 40px;
}

.frm-main {}

.frm-main .frm-main__row {
  display: flex;
  justify-content: space-between;
  margin: 0 0 50px;
  flex-wrap: wrap;
}

.frm-main .frm-main__col {
  display: flex;
  padding: 0 20px;
  margin: 0 0 15px;
  align-items: center;
  width: 100%;
}

.frm-main.frm-2cols .frm-main__col {
  width: 50%;
}

.frm-main.frm-3cols .frm-main__col {
  width: 33.333%;
}

.frm-main .frm-main__col label {
  display: block;
  font-size: 14px;
  width: 200px;
}

.frm-main .frm-main__col input {
  flex: 1 0 auto;
}

.content__footer {
  flex: 0 0 auto;
}

.tbl {
  width: 100%;
  text-align: center;
}

.tbl th {
  font-weight: 700;
  font-size: 14px;
  padding: 10px 10px;
  background-color: #ccc;
}

.tbl td {
  padding: 10px 10px;
  background-color: #ddd;
}

.StyledBox-sc-13pk1d4-0 {
  z-index: 100001;
}

.StyledDrop-sc-16s5rx8-0 {
  z-index: 100001;
}